<template>
  <div>
    <b-container fluid>
      <div class="borderColor">
        <div
          class="text-center"
          style="
            display: flex;
            flex-direction: column;
            padding: 10px;
            background: var(--titleLinear);
          "
        >
          <div class="highcharts-title">
            {{ $t("deviceStatus.deviceLiveInfomation") }}
          </div>
        </div>
        <Highcharts :options="deviceStatusChartOptions" />
      </div>
      <div class="borderColor" style="margin-top: 15px">
        <div class="text-center titlebackgrondstyle">
          <div class="highcharts-title">
            {{ $t("deviceStatus.INVliveInfomation") }}
          </div>
          <div class="highcharts-title">{{ dataTime }}</div>
          <div>
            <button
              class="confirmButton"
              style="width: 120px; margin-left: auto"
              @click="downloadTable"
            >
              {{ $t("dataAnalysis.download") }}
            </button>
          </div>
          <div
            style="
              text-align: left;
              font-size: 18px;
              display: flex;
              align-items: center;
            "
          >
            數量:
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              style="width: 80px; margin: 0 5px"
            ></b-form-select>
            <b-form-checkbox-group
              v-model="pvClass"
              :options="pvOptions"
              style="margin: 5px"
              switches
            />
          </div>
        </div>
        <b-table
          :items="tableData"
          :fields="tableField"
          hover
          class="tableStyle"
          :head-variant="tableThColor"
          :dark="dark"
          :responsive="true"
          :sort-by="sortBy"
          sort-icon-left
          :per-page="perPage"
          :current-page="currentPage"
          :tbody-transition-props="transProps"
          primary-key="INV 名稱"
          sticky-header
        >
          <template #cell(連線狀態)="data">
            <i
              v-if="data.value == 0"
              :class="'fas fa-check-circle'"
              style="color: var(--success)"
            ></i>
            <i
              v-else
              :class="'fas fa-times-circle'"
              style="color: var(--purple)"
            ></i>
          </template>
        </b-table>
      </div>
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="rows"
        class="mb-5"
        style="margin-top: 12px"
      ></b-pagination>
    </b-container>
  </div>
</template>
<script>
import { BFormSelect, BTable, BPagination } from 'bootstrap-vue'
import downloadCsv from 'download-csv'
import { mapState, mapActions } from 'vuex'
// import router from '../router'
// import i18n from '../lang/lang'

export default {
  name: 'devicestatus',
  components: {
    BFormSelect,
    BTable,
    BPagination
  },
  data () {
    return {
      sortBy: 'INV 名稱',
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      pvClass: [],
      pvOptions: [
        {
          value: true,
          text: '僅顯示PV'
        }
      ]
    }
  },
  computed: {
    ...mapState('deviceStatus', [
      'deviceStatusChartOptions',
      'tableField',
      'tableData',
      'dataTime'
    ]),
    ...mapState(['dark', 'tableThColor', 'factoryData']),
    rows () {
      return this.tableData.length
    }
  },
  methods: {
    ...mapActions(['asyncLastSingleDoc', 'updateDevicesStatusState']),
    ...mapActions('deviceStatus', ['handleTableField']),
    downloadTable () {
      // remove _cellVariants
      const downloadData = this.tableData.map((obj) => {
        const { _cellVariants, ...newObject } = obj
        return newObject
      })
      downloadCsv(downloadData, {}, '變流器詳細資訊.csv')
    },
    pvOnly () {
      this.pvClass.length
        ? this.handleTableField(true)
        : this.handleTableField()
    }
  },
  watch: {
    pvClass: function (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.pvOnly()
      }
    }
  },
  async created () {
    if (!this.factoryData.length) {
      await this.asyncLastSingleDoc(this.$route.query.siteName)
    }
    this.updateDevicesStatusState()
    this.handleTableField()
  }
}
</script>
<style lang="scss">
@import "../assets/scss/DeviceStatus.scss";
</style>
